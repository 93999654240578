import React from 'react';
import {
  Block, Container
} from 'react-bulma-components';
import loginGovernWith from 'Login/Assets/GOVERNWITH-Tagline-Terracotta.svg';

import './LogoContainer.scss';

const LogoContainer = () => (
  <Container>
    <Block>
      <figure>
        <img src={loginGovernWith} alt="Govern With" className="LogoContainer-gov-with" />
      </figure>
    </Block>
  </Container>
);

export default LogoContainer;
