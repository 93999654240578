import React, {
    lazy, Suspense
} from 'react';
import { RealmAppProvider } from 'App/RealmAppProvider';
import RequireLoggedInUser from 'App/RequireLoggedInUser';
import RequireCustomData from 'App/RequireCustomData';
import RealmApolloProvider from 'App/RealmApolloProvider';
import APP_ID from 'App/constants';

import Loader from 'Loader/Loader';

const Routing = lazy(() => import('Routing/Routing').then());

const App = () => (
    <RealmAppProvider appId={APP_ID}>
        <RequireLoggedInUser>
            <RequireCustomData>
                <RealmApolloProvider>
                  <Suspense fallback={<Loader />}>
                    <Routing />
                  </Suspense>
                </RealmApolloProvider>
            </RequireCustomData>
        </RequireLoggedInUser>
    </RealmAppProvider>
);

export default App;

    //                 <Suspense fallback={<Loader />}>
    //                     <Routing />
    //                 </Suspense>




