import React, { useState } from 'react';
import LoginBox from 'Login/LoginBox/LoginBox';
// import ThankYouBox from 'Login/ThankYouBox/ThankYouBox';
// import RegisterBox from 'Login/RegisterBox/RegisterBox';
// import ForgotPasswordBox from 'Login/ForgotPasswordBox/ForgotPasswordBox';
import LoginWrapper from 'Login/LoginWrapper/LoginWrapper';
// import ConfirmEmailPage from 'Login/ConfirmEmailPage/ConfirmEmailPage';
// import ResetPasswordBox from 'Login/ResetPasswordBox/ResetPasswordBox';
import MessageBox from 'Login/MessageBox/MessageBox';
// import {
//   Block, Button
// } from 'react-bulma-components';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import './LoginPage.scss';

/**
 * Login Page - holds all the login items. Where user can login or register for portal.
 */
const LoginPage = () => {
  const [showMsgBox, setShowMsgBox] = useState(false);
  const [msgBox, setMsgBox] = useState(
    {
      title: '',
      msg: ''
    }
  );

  return (
    <LoginWrapper>
      {showMsgBox
      && <MessageBox title={msgBox.title} msg={msgBox.msg} setShowMsgBox={setShowMsgBox} />}
        <Router>
          <Switch>
            <Route path="/">
              <LoginBox title="Portal Log In" />
            </Route>
          </Switch>
        </Router>
    </LoginWrapper>
  );
};

export default LoginPage;
