/*
* UAT Realm App Key
 */
const APP_ID = 'uat-admin-governwith-zuzgm';

/*
* Realm App Key
 */
// const APP_ID = 'admin-governwith-dlzra';

export default APP_ID;