import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputEmail from 'Login/InputEmail/InputEmail';
import InputPassword from 'Login/InputPassword/InputPassword';
import {
  Form, Button, Box, Heading
} from 'react-bulma-components';

import './LoginBox.scss';
import { Link } from 'react-router-dom';
import * as Realm from 'realm-web';
import { useRealmApp } from 'App/RealmAppProvider';

/**
 * LoginBox component for portal
 */
const LoginBox = ({ backgroundColor, title }) => {
  const app = useRealmApp();
  const [errorMsg, setErrorMsg] = useState('');
  const [formData, setFormData] = useState(
    {
      email: '',
      password: ''
    }
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, [name]: value
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = formData;
    setLoading(true);
    try {
      await app.logIn(Realm.Credentials.emailPassword(email, password));
    } catch (err) {
      setErrorMsg(err.error);
      setLoading(false);
    }
  };

  return (
    <Box
      style={backgroundColor && {
        backgroundColor
      }}
    >
      <Heading>
        {title}
      </Heading>
      <form onSubmit={(event) => handleLogin(event)}>
        <InputEmail value={formData.email} handleChange={handleChange} />
        <InputPassword value={formData.password} handleChange={handleChange} />
        <Form.Help className="LoginBox-error-div has-text-error">
          {errorMsg}
        </Form.Help>
        <Form.Field>
          <Form.Control>
            <Button.Group align="right">
              <Button
                color="standout"
                submit
                loading={loading}
              >
                LOGIN
              </Button>
            </Button.Group>
          </Form.Control>
        </Form.Field>
      </form>
    </Box>
  );
};

export default LoginBox;

LoginBox.propTypes = {
  /**
   * What background color to use?
   */
  backgroundColor: PropTypes.string,
  /**
   * Title text for component.
   */
  title: PropTypes.string
};

LoginBox.defaultProps = {
  backgroundColor: '#e9e9ea',
  title: 'Log In'
};
